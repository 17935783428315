import { graphql } from 'gatsby';
import { useI18next, useTranslation } from 'gatsby-plugin-react-i18next/dist';
import React, { useEffect } from 'react';
import Header from '../components/HeaderDynamicPages/Header';
import Layout from '../components/Layout/Layout';
import SecondHandMachinesPage from '../components/SecondHandMachinesPage/SecondHandMachinesPage';
import PrinterWrapper from '../components/PrintWrapper/PrintWrapper';
import { SECOND_HAND_MACHINES_FR_LANGUAGES } from '../constants/sitesLanguagesGroup';
import { staticPageRedirection } from '../utils/staticPagesRedirection';
import Alternate from '../components/Seo/Alternate';
import { prepareAlternateData } from '../utils/prepareAlternateData';
import { dataToBreadcrumbsFromNav } from '../utils/dataToBreadCrumbsFromNav';
import secondHandMachinesTranslations from '../JSON/StaticSitesTranslations/secondHandMachinesTranslations.json';
import { getSiteName } from '../utils/getSiteName';

const SecondHandMachines = ({
    data: {
        drupal: {
            used_machines: { data: usedMachinesData },
        },
    },
}) => {
    const { t } = useTranslation();
    const { language } = useI18next();
    const languagesWithUrls = [];
    const { category, categoryItem, item } = dataToBreadcrumbsFromNav(language, 'machines-d-occasion');
    process.env.LANGUAGES.split(',').forEach((language: string) =>
        languagesWithUrls.push(prepareAlternateData(language, 'secondHandMachines'))
    );

    useEffect(() => {
        staticPageRedirection(language, 'secondHandMachines', SECOND_HAND_MACHINES_FR_LANGUAGES);
    }, []);

    return (
        <Layout
            title={`${getSiteName(language)} | ${t('secondHandMachines')}`}
            languageVersionsUrl={secondHandMachinesTranslations}
        >
            <Alternate languages={languagesWithUrls} />
            <Header category={category} categoryItem={categoryItem} item={item} />
            <PrinterWrapper isShmPage={true}>
                <SecondHandMachinesPage usedMachinesData={usedMachinesData} />
            </PrinterWrapper>
        </Layout>
    );
};

export default SecondHandMachines;

export const query = graphql`
    query ($language: String!) {
        drupal {
            used_machines {
                data
            }
        }
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
